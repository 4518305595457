<template>
  <div class="guide-text">{{ error }}</div>
</template>

<script>
import { Toast } from 'vant'
import { getContext } from '../../utils/nwechat'

export default {
  name: 'guide-marketing',
  data() {
    return { error: '' }
  },
  mounted() {
    console.log('this.initEntry()')
    this.initEntry()
  },
  methods: {
    async initEntry() {
      Toast.loading({
        message: '寻路中...',
        duration: 0,
        forbidClick: true
      })
      this.error = ''
      console.log('#1')
      try {
        const entry = await getContext()
        console.log('#2')
        switch (entry) {
          case 'group_chat_tools': // 群聊 - 群建议
            this.$router.replace({
              name: 'marketing-suggestions',
              query: { type: 'group' }
            })
            console.log('#3')
            break
          case 'single_chat_tools': // 单聊 - 营销建议
            this.$router.replace({ name: 'marketing-suggestions' })
            console.log('#4')
            break
          default:
            this.error = '请从聊天工具栏进入！'
        }
      } catch (e) {
        console.log('#5')
        console.log(e)
        this.error = '请重试！'
      }
      console.log('#6')
      Toast.clear()
    }
  }
}
</script>

<style lang="less" scoped>
.guide-text {
  padding: 24px 36px;
  text-align: center;
  color: #969799;
  font-size: 18px;
}
</style>
